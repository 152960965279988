// Main.js
import Layout from "../../components/layout/Layout"
import Loader from "../../components/common/Loader"
import { Suspense } from "react"

const Main = ({ children }) => {
	return (
		<Layout>
			<Suspense fallback={<Loader />}>{children}</Suspense>
		</Layout>
	)
}

export default Main
