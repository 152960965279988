import { Suspense } from "react";
import "./App.css";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
} from "react-router-dom";
import { routes } from "./routes/routes";
import Loader from "./components/common/Loader";
import Main from "./pages/main/Main";
import MainWithoutSidebar from "./pages/main/MainWithoutSidebar";
import useTokenRefresh from "./hooks/useTokenRefresh";

function App() {
  useTokenRefresh();

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path="/" element={<Navigate to={routes.signin.path} />} />
        <Route path={routes.signin.path} element={<routes.signin.element />} />
        <Route path={routes.signup.path} element={<routes.signup.element />} />

        <Route
          path={routes.user.path}
          element={
            <MainWithoutSidebar>
              <routes.user.element />
            </MainWithoutSidebar>
          }
        >
          {/* <Route
            path={routes.downloadViewSingleTaxInvoice.path}
            element={<routes.downloadViewSingleTaxInvoice.element />}
          ></Route> */}

          {/* <Route
            path={routes.downloadViewSingleEWayBill.path}
            element={<routes.downloadViewSingleEWayBill.element />}
          ></Route> */}
          <Route
            path={routes.EwayBillView.path}
            element={<routes.EwayBillView.element />}
          />
          <Route
            path={routes.EwayBillWithIrnView.path}
            element={<routes.EwayBillWithIrnView.element />}
          />
          <Route
            path={routes.EwayBillWithOutIrnView.path}
            element={<routes.EwayBillWithOutIrnView.element />}
          />
          <Route
            path={routes.EwayBillTaxInvoiceView.path}
            element={<routes.EwayBillTaxInvoiceView.element />}
          />
          <Route
            path={routes.EwayBillEInvoiceView.path}
            element={<routes.EwayBillEInvoiceView.element />}
          />
          <Route
            path={routes.userProfile.path}
            element={<routes.userProfile.element />}
          />
          <Route
            path={routes.subscriptionUpdate.path}
            element={<routes.subscriptionUpdate.element />}
          />
          <Route
            path={routes.subscriptionWithTrial.path}
            element={<routes.subscriptionWithTrial.element />}
          />
          <Route
            path={routes.subscriptionHistory.path}
            element={<routes.subscriptionHistory.element />}
          />
        </Route>

        <Route
          path={routes.user.path}
          element={
            <Main>
              <routes.user.element />
            </Main>
          }
        >
          <Route
            path={routes.EwayBillTaxInvoiceView.path}
            element={<routes.EwayBillTaxInvoiceView.element />}
          />
          <Route
            path={routes.EwayBillEInvoiceView.path}
            element={<routes.EwayBillEInvoiceView.element />}
          />
          <Route
            path={routes.Recurring_Invoice.path}
            element={<routes.Recurring_Invoice.element />}
          />
          <Route
            path={routes.Recurring_Invoice_View.path}
            element={<routes.Recurring_Invoice_View.element />}
          />
          <Route
            path={routes.EwayBillWithIrnView.path}
            element={<routes.EwayBillWithIrnView.element />}
          />
          <Route
            path={routes.EwayBillWithOutIrnView.path}
            element={<routes.EwayBillWithOutIrnView.element />}
          />
          <Route
            path={routes.dashboard.path}
            element={<routes.dashboard.element />}
          />
          <Route
            path={routes.estimate.path}
            element={<routes.estimate.element path={0} />}
          />

          <Route
            path={routes.convertedEstimate.path}
            element={<routes.convertedEstimate.element path={1} />}
          />
          <Route
            path={routes.estimateCreate.path}
            element={<routes.estimateCreate.element />}
          />
          <Route
            path={routes.eInvoice.path}
            element={<routes.eInvoice.element />}
          />
          <Route
            path={routes.create_e_invoice.path}
            element={<routes.create_e_invoice.element />}
          />
          <Route
            path={routes.estimateView.path}
            element={<routes.estimateView.element />}
          />
          <Route
            path={routes.estimateEdit.path}
            element={<routes.estimateEdit.element />}
          />
          <Route
            path={routes.invoice.path}
            element={<routes.invoice.element />}
          />
          <Route
            path={routes.create_tax_invoice.path}
            element={<routes.create_tax_invoice.element />}
          />
          <Route
            path={routes.create_credit_note_from_taxInvoice.path}
            element={<routes.create_credit_note_from_taxInvoice.element />}
          />
          <Route
            path={routes.create_credit_note_from_eInvoice.path}
            element={<routes.create_credit_note_from_eInvoice.element />}
          />

          <Route
            path={routes.create_debit_note_from_taxInvoice.path}
            element={<routes.create_debit_note_from_taxInvoice.element />}
          />
          <Route
            path={routes.create_debit_note_from_eInvoice.path}
            element={<routes.create_debit_note_from_eInvoice.element />}
          />

          <Route
            path={routes.invoiceConverter.path}
            element={<routes.invoiceConverter.element />}
          />
          <Route
            path={routes.envoiceToEwayBillConverter.path}
            element={<routes.envoiceToEwayBillConverter.element />}
          />
          <Route
            path={routes.einvoiceConverter.path}
            element={<routes.einvoiceConverter.element />}
          />
          <Route
            path={routes.invoiceToEwayBillConverter.path}
            element={<routes.invoiceToEwayBillConverter.element />}
          />

          <Route
            path={routes.userProfile.path}
            element={<routes.userProfile.element />}
          />

          <Route
            path={routes.profileSetUp.path}
            element={<routes.profileSetUp.element />}
          />
          <Route
            path={routes.EWayBillWithIrnCreateForm.path}
            element={<routes.EWayBillWithIrnCreateForm.element />}
          />
          <Route
            path={routes.EWayBillWithOutIrnCreateForm.path}
            element={<routes.EWayBillWithOutIrnCreateForm.element />}
          />
          <Route
            path={routes.EWayBillExternal.path}
            element={<routes.EWayBillExternal.element />}
          />
          <Route
            path={routes.EwayBillCreateFormWithTaxInvoice.path}
            element={<routes.EwayBillCreateFormWithTaxInvoice.element />}
          />

          <Route
            path={routes.convertedEstimate.path}
            element={<routes.convertedEstimate.element path={1} />}
          />
          <Route
            path={routes.estimateCreate.path}
            element={<routes.estimateCreate.element />}
          />
          <Route
            path={routes.eInvoice.path}
            element={<routes.eInvoice.element />}
          />
          <Route
            path={routes.create_e_invoice.path}
            element={<routes.create_e_invoice.element />}
          />
          <Route
            path={routes.estimateView.path}
            element={<routes.estimateView.element />}
          />
          <Route
            path={routes.self_invoice_view.path}
            element={<routes.self_invoice_view.element />}
          />
          <Route
            path={routes.estimateEdit.path}
            element={<routes.estimateEdit.element />}
          />
          <Route
            path={routes.invoice.path}
            element={<routes.invoice.element />}
          />
          <Route
            path={routes.create_tax_invoice.path}
            element={<routes.create_tax_invoice.element />}
          />
          <Route
            path={routes.AllSelfInvoice.path}
            element={<routes.AllSelfInvoice.element />}
          />

          <Route
            path={routes.create_self_invoice.path}
            element={<routes.create_self_invoice.element />}
          />
          <Route
            path={routes.create_credit_note_from_taxInvoice.path}
            element={<routes.create_credit_note_from_taxInvoice.element />}
          />
          <Route
            path={routes.create_credit_note_from_eInvoice.path}
            element={<routes.create_credit_note_from_eInvoice.element />}
          />

          <Route
            path={routes.invoiceConverter.path}
            element={<routes.invoiceConverter.element />}
          />
          <Route
            path={routes.einvoiceConverter.path}
            element={<routes.einvoiceConverter.element />}
          />
          <Route
            path={routes.userProfile.path}
            element={<routes.userProfile.element />}
          />
          <Route
            path={routes.tax_invoice_edit.path}
            element={<routes.tax_invoice_edit.element />}
          ></Route>
          <Route
            path={routes.profileSetUp.path}
            element={<routes.profileSetUp.element />}
          />
          <Route
            path={routes.EWayBillWithIrnCreateForm.path}
            element={<routes.EWayBillWithIrnCreateForm.element />}
          />
          <Route
            path={routes.EWayBillWithOutIrnCreateForm.path}
            element={<routes.EWayBillWithOutIrnCreateForm.element />}
          />
          <Route
            path={routes.EWayBillExternal.path}
            element={<routes.EWayBillExternal.element />}
          />
          <Route
            path={routes.EwayBillCreateFormWithTaxInvoice.path}
            element={<routes.EwayBillCreateFormWithTaxInvoice.element />}
          />

          <Route
            path={routes.EWayBillInternal.path}
            element={<routes.EWayBillInternal.element />}
          />
          <Route
            path={routes.Products.path}
            element={<routes.Products.element />}
          ></Route>
          <Route
            path={routes.ProductView.path}
            element={<routes.ProductView.element />}
          ></Route>
          <Route
            path={routes.customers.path}
            element={<routes.customers.element />}
          ></Route>
          <Route
            path={routes.EditCustomer.path}
            element={<routes.EditCustomer.element pathType={0} />}
          ></Route>
          <Route
            path={routes.EditCustomerInvoiceView.path}
            element={<routes.EditCustomerInvoiceView.element pathType={1} />}
          ></Route>
          <Route
            path={routes.viewSingleTaxInvoice.path}
            element={<routes.viewSingleTaxInvoice.element />}
          ></Route>
          <Route
            path={routes.viewSingleEInvoice.path}
            element={<routes.viewSingleEInvoice.element />}
          ></Route>
          <Route
            path={routes.viewSingleTaxInvoiceForCustomer.path}
            element={
              <routes.viewSingleTaxInvoice.element
                pathType={"fromCustomerPage"}
              />
            }
          ></Route>
          <Route
            path={routes.viewSingleEInvoiceForCustomer.path}
            element={
              <routes.viewSingleEInvoiceForCustomer.element
                pathType={"fromCustomerPage"}
              />
            }
          ></Route>
        </Route>
        <Route path="/*" element={<routes.invalid.element />} />
      </Route>
    )
  );

  return (
    <Suspense fallback={<Loader />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
