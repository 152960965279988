import { useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

function useTokenRefresh() {
  useEffect(() => {
    const auth = getAuth();
    let refreshInterval;

    const refreshToken = () => {
      const user = auth.currentUser;
      if (user) {
        user.getIdToken(true).catch((error) => {
          console.error("Token refresh failed:", error);
          // Optionally, handle token refresh errors here
        });
      }
    };

    const setupTokenRefresh = (user) => {
      if (user) {
        refreshToken(); // Refresh immediately on login

        // Set up a periodic refresh every 55 minutes
        refreshInterval = setInterval(() => {
          refreshToken();
        }, 55 * 60 * 1000); // 55 minutes
      } else {
        // Clear the interval if no user is signed in
        clearInterval(refreshInterval);
      }
    };

    // Monitor authentication state
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setupTokenRefresh(user);
    });

    return () => {
      clearInterval(refreshInterval); // Clean up on unmount
      unsubscribe(); // Clean up auth state listener
    };
  }, []);
}

export default useTokenRefresh;
