import { createContext, useState, useEffect } from "react"
import { auth } from "../../firebase_config"

const AuthContext = createContext({
	authData: null,
	setAuthData: () => {},
})

const AuthProvider = ({ children }) => {
	const [authData, setAuthData] = useState(null)
	const [authLoader, setAuthLoader] = useState(true)

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user) {
				setAuthData(user)
			} else {
				setAuthData(null)
			}
			setAuthLoader(false)
		})

		return () => {
			unsubscribe() // Clean up the listener when the component unmounts
		}
	}, [])

	return (
		<AuthContext.Provider value={{ authData, setAuthData, authLoader }}>
			{children}
		</AuthContext.Provider>
	)
}

export { AuthContext, AuthProvider }
