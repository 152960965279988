import { createSlice } from "@reduxjs/toolkit";

const eInvoiceSlice = createSlice({
  name: "eInvoice",
  initialState: {
    InvoiceSlicePaginationData: {
      currentPage: 1,
      searchText: "",
      totalCount: 0,
      isLoading: true,
    },
    PendingInvoiceSlicePaginationData: {
      currentPage: 1,
      searchText: "",
      totalCount: 0,
      isLoading: true,
    },
    PaidInvoiceSlicePaginationData: {
      currentPage: 1,
      searchText: "",
      totalCount: 0,
      isLoading: true,
    },
    allInvoicesData: null,
    pendingInvoiceData: null,
    paidInvoiceData: null,
    customerGSTList: null,
  },
  reducers: {
    addEInvoicePaginationData: function (state, action) {
      state.InvoiceSlicePaginationData = action.payload;
    },
    addAllEInvoicesData: function (state, action) {
      state.allInvoicesData = action.payload;
    },
    addPendingEInvoicePaginationData: function (state, action) {
      state.PendingInvoiceSlicePaginationData = action.payload;
    },
    addPendingEInvoicesData: function (state, action) {
      console.log(state, action);
      state.pendingInvoiceData = action.payload;
    },
    addPaidEInvoicePaginationData: function (state, action) {
      state.PaidInvoiceSlicePaginationData = action.payload;
    },
    addPaidEInvoicesData: function (state, action) {
      state.paidInvoiceData = action.payload;
    },
    addCustomerGSTList: function (state, action) {
      state.customerGSTList = action.payload;
    },
  },
});
export const {
  addEInvoicePaginationData,
  addAllEInvoicesData,
  addCustomerGSTList,
  addPaidEInvoicePaginationData,
  addPaidEInvoicesData,
  addPendingEInvoicePaginationData,
  addPendingEInvoicesData,
} = eInvoiceSlice.actions;
export default eInvoiceSlice.reducer;
