// FunctionContext.js
import React, { createContext, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AuthContext } from "./AuthContext";
import common_service from "../api/common_service";
import { baseUrl, endpoints } from "../utils/axios";
import {
  addAllInvoicesData,
  addInvoicePaginationData,
  addPaidInvoicePaginationData,
  addPaidInvoicesData,
  addPendingInvoicePaginationData,
  addPendingInvoicesData,
} from "../redux/invoice/invoiceSlice";
import {
  addAllEInvoicesData,
  addEInvoicePaginationData,
  addPaidEInvoicePaginationData,
  addPaidEInvoicesData,
  addPendingEInvoicePaginationData,
  addPendingEInvoicesData,
} from "../redux/e-invoice/eInvoiceSlice";

export const FunctionContext = createContext();

export const FunctionProvider = ({ children }) => {
  const companyData = useSelector((state) => state.companySlice.companyData);
  const InvoiceSlicePaginationData = useSelector(
    (state) => state.invoiceSlice.InvoiceSlicePaginationData
  );
  const PendingInvoiceSlicePaginationData = useSelector(
    (state) => state.invoiceSlice.PendingInvoiceSlicePaginationData
  );
  const PaidInvoiceSlicePaginationData = useSelector(
    (state) => state.invoiceSlice.PaidInvoiceSlicePaginationData
  );

  const EInvoiceSlicePaginationData = useSelector(
    (state) => state.eInvoiceSlice.InvoiceSlicePaginationData
  );
  const PendingEInvoiceSlicePaginationData = useSelector(
    (state) => state.eInvoiceSlice.PendingInvoiceSlicePaginationData
  );
  const PaidEInvoiceSlicePaginationData = useSelector(
    (state) => state.eInvoiceSlice.PaidInvoiceSlicePaginationData
  );
  const authData = useContext(AuthContext);
  const dispatch = useDispatch();

  const getAllInvoice = async (filter) => {
    let url = `${baseUrl}/${endpoints.invoice.getAllInvoices}/${
      companyData?.company_id
    }?page=${InvoiceSlicePaginationData.currentPage}&search=${
      InvoiceSlicePaginationData.searchText
    }&pageSize=${10}&sortOrderDate=${
      filter?.sortByLatestCreated
    }&sortOrderValue=${filter.sortByAmount}&paymentStatus=${
      filter?.paymentStatus
    }&hasEwayBill=${filter?.ewayBillPresent}&range=${filter.range}&typ=${
      filter.typ
    }&startDate=${filter.date.from}&endDate=${filter.date.to}&company_gst_id=${
      filter?.company_gst_id
    }`;
    const response = await common_service.GET_REQUEST(
      url,
      authData.authData.accessToken
    );
    if (response?.status >= 200 && response?.status <= 300) {
      // !allInvoiceList?.length &&
      //   setAllInvoiceList(response.data?.taxInvoices || response.data.invoices);
      addDataInRedux(response, filter);
    } else {
      addToast(4, "Something went wrong");
    }
  };

  const addDataInRedux = (response, filter) => {
    if (filter.paymentStatus == "Pending") {
      dispatch(
        addPendingInvoicePaginationData({
          ...PendingInvoiceSlicePaginationData,
          totalCount: response?.data?.totalCount,
        })
      );
      dispatch(
        addPendingInvoicesData(
          response.data?.taxInvoices || response.data.invoices
        )
      );
    } else if (filter.paymentStatus == "Received") {
      dispatch(
        addPaidInvoicePaginationData({
          ...PaidInvoiceSlicePaginationData,
          totalCount: response.data.totalCount,
        })
      );
      dispatch(
        addPaidInvoicesData(
          response.data?.taxInvoices || response.data.invoices
        )
      );
    } else {
      dispatch(
        addInvoicePaginationData({
          ...InvoiceSlicePaginationData,
          totalCount: response.data.totalCount,
          isLoading: false,
        })
      );
      dispatch(
        addAllInvoicesData(response.data?.taxInvoices || response.data.invoices)
      );
    }
  };

  const getAllEInvoice = async (filter) => {
    let url = `${baseUrl}/${endpoints.e_invoice.getAllInvoices(
      companyData?.company_id
    )}/?page=${EInvoiceSlicePaginationData.currentPage}&search=${
      EInvoiceSlicePaginationData.searchText
    }&pageSize=${10}&sortOrderDate=${
      filter?.sortByLatestCreated
    }&sortOrderValue=${filter.sortByAmount}&paymentStatus=${
      filter?.paymentStatus
    }&hasEwayBill=${filter?.ewayBillPresent}&range=${filter.range}&typ=${
      filter.typ
    }&startDate=${filter.date.from}&endDate=${filter.date.to}&company_gst_id=${
      filter?.company_gst_id
    }`;
    const response = await common_service.GET_REQUEST(
      url,
      authData.authData.accessToken
    );
    if (response?.status >= 200 && response?.status <= 300) {
      // !allInvoiceList?.length &&
      //   setAllInvoiceList(response.data?.eInvoices || response.data.invoices);
      addDataInRedux2(response, filter);
    } else {
      addToast(4, "Something went wrong");
    }
  };

  const addDataInRedux2 = (response, filter) => {
    if (filter.paymentStatus == "Pending") {
      dispatch(
        addPendingEInvoicePaginationData({
          ...PendingEInvoiceSlicePaginationData,
          totalCount: response.data.totalCount,
        })
      );
      dispatch(
        addPendingEInvoicesData(
          response.data?.eInvoices || response.data.invoices
        )
      );
    } else if (filter.paymentStatus == "Received") {
      dispatch(
        addPaidEInvoicePaginationData({
          ...PaidEInvoiceSlicePaginationData,
          totalCount: response.data.totalCount,
        })
      );
      dispatch(
        addPaidEInvoicesData(response.data?.eInvoices || response.data.invoices)
      );
    } else {
      dispatch(
        addEInvoicePaginationData({
          ...EInvoiceSlicePaginationData,
          totalCount: response?.data?.totalCount || 0,
          isLoading: false,
        })
      );
      dispatch(
        addAllEInvoicesData(
          response?.data?.eInvoices || response?.data?.invoices || []
        )
      );
    }
  };

  return (
    <FunctionContext.Provider value={{ getAllInvoice, getAllEInvoice }}>
      {children}
    </FunctionContext.Provider>
  );
};
