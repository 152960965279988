// import { configureStore } from "@reduxjs/toolkit"
// import companySlice from "./company/companySlice"
// import itemsSlice from "./items/itemsSlice"
// import customerSlice from "./customer/customerSlice"
// import invoiceSlice from "./invoice/invoiceSlice"
// import estimateSlice from "./estimate/estimateSlice"
// import ewaybillSlice from "./ewaybill/ewaybillSlice"
// import eInvoiceSlice from "./e-invoice/eInvoiceSlice"

// export const store = configureStore({
// 	reducer: {
// 		companySlice: companySlice,
// 		itemsSlice: itemsSlice,
// 		customerSlice: customerSlice,
// 		invoiceSlice: invoiceSlice,
// 		eInvoiceSlice: eInvoiceSlice,
// 		// customerSearch: customerSearchSlice,
// 		estimateSlice: estimateSlice,
// 		ewaybillSlice: ewaybillSlice,
// 	},
// })
// export default store

import { configureStore } from "@reduxjs/toolkit"
import rootReducer from "./rootReducer"

export const store = configureStore({
	reducer: rootReducer,
})

export default store
