import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

const customerSlice = createSlice({
	name: "customer",
	initialState: {
		customerAllData: null,
		customerSearchResults: null,
		totalItemsForPagination: 0,
		CustomerPaginationData: {
			currentPage: 1,
			searchText: "",
			isLoading:true
		},
		filter:{
          gst:null,
		  date:null
		},
		customerEditData:null
	},
	reducers: {
		addAnyCustomerSliceData: function (state, action) {
			return action.payload
		},
		addCustomerData: function (state, action) {
			state.customerAllData = action.payload
		},
		addCustomerEditData: function (state, action) {
			state.customerEditData = action.payload
		},
		addCustomerSearchResults: function (state, action) {
			console.log(action.payload)
			state.customerSearchResults = action.payload
		},
		addTotalItemsForPagination: function (state, action) {
			state.totalItemsForPagination = action.payload
		},
		addCustomerPaginationData: function (state, action) {
			state.CustomerPaginationData = action.payload
		},
	},
})
export const {
	addCustomerData,
	addCustomerSearchResults,
	addTotalItemsForPagination,
	addCustomerPaginationData,
	addCustomerEditData,
	addAnyCustomerSliceData
} = customerSlice.actions
export default customerSlice.reducer
