// Main.js
import Loader from "../../components/common/Loader"
import { Suspense } from "react"
import LayoutWithoutSidebar from "../../components/layout/LayoutWithoutSidebar"

const Main = ({ children }) => {
	return (
		<LayoutWithoutSidebar>
			<Suspense fallback={<Loader />}>{children}</Suspense>
		</LayoutWithoutSidebar>
	)
}

export default Main
