// context/ModalContext.jsx
import React, { createContext, useState } from "react";
import TokenExpirePopUp from "../components/common/TokenExpirePopUp";

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const showModal = (msg) => {
    setMessage(msg);
    setIsOpen(true);
  };

  const hideModal = () => {
    setMessage("");
    setIsOpen(false);
  };

  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      {children}
      {isOpen && <TokenExpirePopUp message={message} onClose={hideModal} />}
    </ModalContext.Provider>
  );
};
