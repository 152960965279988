// Layout.js

import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../sidebar/Sidebar";
import TopNavBar from "../Top-navbar/TopNavBar";
import { useState } from "react";
import { addGlobalAnySliceData } from "../../redux/global/globalSlice";
import { ErrorBoundary } from "react-error-boundary";
import ErrorsComponent from "../common/ErrorsComponent";
import { useLocation } from "react-router-dom";

const LayoutWithoutSidebar = ({ children }) => {
  const [drawerSideBar, setDrawerSidebar] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const globalSliceData = useSelector((state) => state.globalSlice);

  return (
    <div>
      {/* this is for change the total back ground color  blak*/}
      {globalSliceData.popUp.index !== null && (
        <div
          onClick={() =>
            globalSliceData.popUp.ind === true
              ? dispatch(
                  addGlobalAnySliceData({
                    ...globalSliceData,
                    popUp: { type: null, index: null },
                  })
                )
              : null
          }
          className="fixed inset-0 h-full   flex flex-col justify-center items-center overflow-y-auto overflow-x-hidden bg-black bg-opacity-70 z-30 w-full"
        ></div>
      )}
      <div className="relative z-20 ">
        <TopNavBar />
      </div>
      <div className="flex">
        <>
          <ErrorBoundary
            key={location.pathname}
            fallback={<ErrorsComponent></ErrorsComponent>}
          >
            <main className=" flex-grow bg-[#FAF8FE] mt-[2rem] relative">
              <div id="toast-container"></div>
              {children}
            </main>
          </ErrorBoundary>
        </>
      </div>
    </div>
  );
};

export default LayoutWithoutSidebar;
