import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    stats: null,
    monthlyStats: null,
    pendingInvoices: null
}

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        addStats: function (state, action) {
            state.stats = action.payload
        },
        addMonthlyStats: function (state, action) {
            state.monthlyStats = action.payload
        },
        addPendingInvoices: function (state, action) {
            state.pendingInvoices = action.payload
        }
    }
})

export const {
    addStats,
    addMonthlyStats,
    addPendingInvoices
} = dashboardSlice.actions

export default dashboardSlice.reducer