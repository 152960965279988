import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

const convertedEstimate = createSlice({
	name: "invoice",
	initialState: {
		totalCount:0,
		EstimatePaginationData: {
			currentPage: 1,
			searchText: "",
			isLoading:true,
		},
		filter:{
		   converted:null,
		   status:null
		},
		allEstimateData:[]
	},
	reducers: {
		addConvertedEstimateTotalCount: function (state, action) {
			return action.payload
		}, 
		addConvertedEstimatePaginationData: function (state, action) {
			state.EstimatePaginationData = action.payload
		},
		addAllConvertedEstimateData:function (state, action) {
			state.allEstimateData = action.payload
		},
		addConvertedEstimateFilter:function (state, action) {
			state.filter = {...state.filter,status:action.payload}
		}
	},
})
export const {
	addConvertedEstimatePaginationData,addAllConvertedEstimateData,addConvertedEstimatTotalCount,addConvertedEstimatFilter
} = convertedEstimate.actions
export default convertedEstimate.reducer