// redux/rootReducer.js
import { combineReducers } from "@reduxjs/toolkit"
import companyReducer from "./company/companySlice"
import itemsReducer from "./items/itemsSlice"
import customerReducer from "./customer/customerSlice"
import invoiceReducer from "./invoice/invoiceSlice"
import estimateReducer from "./estimate/estimateSlice"
import ewaybillReducer from "./ewaybill/ewaybillSlice"
import eInvoiceReducer from "./e-invoice/eInvoiceSlice"
import selfInvoiceReducer from "./self-invoice/selfInvoiceSlice"
import convertedEstimate from "./estimate/convertedEstimate/convertedEstimate"
import recurring_InvoiceSlice from "./Recurring_Invoice/recurring_InvoiceSlice"

import dahsboardReducer from "./dashboard/dashboardSlice"
import globalSlice from "./global/globalSlice"
// Combine all your slices here
const appReducer = combineReducers({
	companySlice: companyReducer,
	itemsSlice: itemsReducer,
	customerSlice: customerReducer,
	invoiceSlice: invoiceReducer,
	estimateSlice: estimateReducer,
	ewaybillSlice: ewaybillReducer,
	eInvoiceSlice: eInvoiceReducer,
	convertedEstimate: convertedEstimate,
	dashboard: dahsboardReducer,
	globalSlice: globalSlice,
	selfInvoice: selfInvoiceReducer,
	recurring_InvoiceSlice:recurring_InvoiceSlice
})

// Create a rootReducer that resets the state on logout
const rootReducer = (state, action) => {
	if (action.type === "user/logout") {
		state = undefined
	}
	return appReducer(state, action)
}

export default rootReducer
