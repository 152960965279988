import { createAsyncThunk, createSlice } from '@reduxjs/toolkit' 


const itemSlice=createSlice({
    name:"item",
    initialState:{
        itemData:null,
        singleItem:null,
        paginationData:{
          currentPage: 1,
          searchText: "",
          isLoading:true,
          totalCount:0
        },

    },
    reducers:{
      addItemData: function(state,action){
        state.itemData=action.payload;
     },
     addSingleItem: function(state,action){
      state.singleItem=action.payload;
   },
   addItemPagination: function(state,action){
    state.paginationData=action.payload;
 },
 addAnyItemData: function(state,action){
   return action.payload;
}
    }
})
export const {addItemData,addSingleItem,addItemPagination,addAnyItemData}=itemSlice.actions;
export default itemSlice.reducer

