import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const estimateSlice = createSlice({
  name: "invoice",
  initialState: {
    totalCount: 0,
    EstimatePaginationData: {
      currentPage: 1,
      searchText: "",
      isLoading: true,
    },
    totalPageCountForConvertedEstimmates: 0,
    ConvertedEstimatePaginationData: {
      currentPage: 1,
      searchText: "",
      isLoading: true,
    },
    filter: {
      converted: null,
      status: null,
    },
    allEstimateData: null,
    convertedEstimateData: null,
  },
  reducers: {
    addEstimatSliceeAnyData: function (state, action) {
      return action.payload;
    },
    addEstimatePaginationData: function (state, action) {
      state.EstimatePaginationData = action.payload;
    },
    addAllEstimateData: function (state, action) {
      state.allEstimateData = action.payload;
    },
    addFilter: function (state, action) {
      state.filter = { ...state.filter, status: action.payload };
    },
  },
});
export const {
  addEstimatePaginationData,
  addAllEstimateData,
  addEstimatSliceeAnyData,
  addFilter,
} = estimateSlice.actions;
export default estimateSlice.reducer;
