// import messaging from "./FirebaseInit";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "../firebase_config";
import { baseUrl, endpoints } from "./utils/axios";
import common_service from "./api/common_service";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";

export const initializePushNotifications = async (authData) => {
  // Request permission for push notifications

  //   const permission = await Notification.requestPermission();
  //   if (permission == "granted") {
  //     const token = await getToken(messaging, {
  //       vapidKey:
  //         "BNJS87L6GN21EmSgbmXxvLxreIO3u4veGaBAzE2MRk90v7nk2lNEavy27AgsWL5D-yz-CrNEBjnpoIa9Op4hzQs",
  //     });
  //     console.log(token);
  //     if (token) sendNotificationTokenToDB(token);
  //   } else if (permission == "denied") {
  //     alert("u denied the notification permission");
  //   }

  const sendNotificationTokenToDB = async (notificationToken) => {
    let url = `${baseUrl}/${endpoints.notification.sendTokenToDb}`;
    const res = await common_service.POST_REQUEST(
      url,
      authData.authData.accessToken,
      { token: notificationToken }
    );
    if (res.status >= 200 && res.status <= 300) null;
    else console.log(res);
  };

  Notification.requestPermission()
    .then(async (permission) => {
      if (permission === "granted") {
        // Get the token from the messaging service
        let notifyToken = await getToken(messaging, {
          vapidKey:
            "BAkMRicWkwdrRubkwCo8Jbx4dlvCyit3N59T_e6zgTk9pBt4PEx-ywJ0eEaNv1JMvvch6oC63EIru3x7KGNypJs",
        });
        return notifyToken;
      } else {
        console.log("Unable to get permission to notify.");
      }
    })
    .then((token) => {
      sendNotificationTokenToDB(token);
      // Send this token to your server to associate it with the user
    })
    .catch((err) => {
      console.error("Error getting notification permission:", err);
    });

  // Handle incoming messages
  onMessage(messaging, (payload) => {
    console.log("Message received:", payload);
    // Display notification using Notification API
    if (Notification.permission === "granted") {
      new Notification(payload.notification.title, {
        body: payload.notification.body,
        icon: payload.notification.icon,
      });
    }
  });
};
