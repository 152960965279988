import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

const invoiceSlice = createSlice({
	name: "invoice",
	initialState: {
		InvoiceSlicePaginationData: {
			currentPage: 1,
			searchText: "",
			totalCount: 0,
			isLoading: true,
		},
		PendingInvoiceSlicePaginationData: {
			currentPage: 1,
			searchText: "",
			totalCount: 0,
			isLoading: true,
		},
		PaidInvoiceSlicePaginationData: {
			currentPage: 1,
			searchText: "",
			totalCount: 0,
			isLoading: true,
		},
		allInvoicesData: null,
		pendingInvoiceData: null,
		paidInvoiceData: null,
		customerGSTList: null,
	},
	reducers: {
		addInvoicePaginationData: function (state, action) {
			state.InvoiceSlicePaginationData = action.payload
		},
		addAllInvoicesData: function (state, action) {
			state.allInvoicesData = action.payload
		},
		addPendingInvoicePaginationData: function (state, action) {
			state.PendingInvoiceSlicePaginationData = action.payload
		},
		addPendingInvoicesData: function (state, action) {
			state.pendingInvoiceData = action.payload
		},
		addPaidInvoicePaginationData: function (state, action) {
			state.PaidInvoiceSlicePaginationData = action.payload
		},
		addPaidInvoicesData: function (state, action) {
			state.paidInvoiceData = action.payload
		},
		addCustomerGSTList: function (state, action) {
			state.customerGSTList = action.payload
		},
	},
})
export const {
	addInvoicePaginationData,
	addAllInvoicesData,
	addCustomerGSTList,
	addPaidInvoicePaginationData,
	addPaidInvoicesData,
	addPendingInvoicePaginationData,
	addPendingInvoicesData,
} = invoiceSlice.actions
export default invoiceSlice.reducer
