import { lazy } from "react";

const Signup = lazy(() => import("../pages/authentication/sign_up/Signup"));
const Signin = lazy(() => import("../pages/authentication/sign_in/Signin.jsx"));
const ErrorComp = lazy(() => import("../components/common/ErrorComponent"));
const PrivateRoute = lazy(() => import("../auth/PrivateRote.jsx"));
const Dashboard = lazy(() => import("../pages/dashboard/Dashboard.jsx"));
const Invoice = lazy(() => import("../pages/invoice/Invoice.jsx"));

const profileSetUp = lazy(() =>
  import("../pages/profileSetUp/ProfileSetUp.jsx")
);
const Products = lazy(() => import("../pages/Items/Products.jsx"));
const ProductView = lazy(() => import("../pages/Items/ProductView.jsx"));
const Customer = lazy(() => import("../pages/customer/Customer.jsx"));
const EditCustomer = lazy(() => import("../pages/customer/EditCustomer.jsx"));
const EditInvoicePage = lazy(() =>
  import("../pages/invoice/edit/EditInvoiceForm.jsx")
);
const UserProfile = lazy(() => import("../pages/userProfile/UserProfile.jsx"));
const Estimate = lazy(() => import("../pages/Estimate/Estimate.jsx"));
const EditEstimate = lazy(() => import("../pages/Estimate/CreateEstimate.jsx"));
const EstimateView = lazy(() => import("../pages/Estimate/EstimateView.jsx"));
const TaxInvoiceToEwaybill = lazy(() =>
  import("../components/Convertions/TaxInvoiceToEwayBill.jsx")
);
const EinvoiceToEwayBill = lazy(() =>
  import("../components/Convertions/EinvoiceToEwayBill.jsx")
);
import("../pages/invoice/edit/EditInvoiceForm.jsx");

const SelfInvoice_View = lazy(() =>
  import("../pages/self_invoice_view/SelfInvoice_view.jsx")
);
const invoiceConverter = lazy(() =>
  import("../components/invoice/CreateInvoiceForm.jsx")
);
const Main = lazy(() => import("../pages/main/Main.jsx"));

const InvoiceView = lazy(() => import("../pages/invoice-view/InvoiceView.jsx"));
const CreateTaxInvoice = lazy(() =>
  import("../pages/create-tax-invoice/TaxInvoiceForm.jsx")
);
const SelfInvoice = lazy(() =>
  import("../pages/AllSelfInvoice/SelfInvoice.jsx")
);

const CreateSelfInvoice = lazy(() =>
  import("../pages/create-self-invoice/SelfInvoiceForm.jsx")
);

const CreateCreditNote = lazy(() =>
  import("../pages/credit-note/CreateCreditNote.jsx")
);
const CreateDebitNote = lazy(() =>
  import("../pages/debit-note/CreateDebitNote.jsx")
);
const EInvoiceView = lazy(() =>
  import("../pages/e-invoice-view/EInvoiceView.jsx")
);
const CreateEInvoice = lazy(() =>
  import("../pages/create-e-invoice/EInvoiceForm.jsx")
);
const einvoiceConverter = lazy(() =>
  import("../pages/create-e-invoice/EInvoiceForm.jsx")
);
const EWayBillWithIrnCreateForm = lazy(() =>
  import("../pages/E_Way_Bill/Forms/EWayBillWithIrnCreateForm.jsx")
);
const EWayBillWithOutIrnCreateForm = lazy(() =>
  import("../pages/E_Way_Bill/Forms/EWayBillWithOutIrnCreateForm.jsx")
);
const EWayBillExternal = lazy(() =>
  import("../pages/E_Way_Bill/external/EWayBillExternal.jsx")
);
const EWayBillInternal = lazy(() =>
  import("../pages/E_Way_Bill/internal/EWayBillInternal.jsx")
);
const EwayBillView = lazy(() =>
  import("../pages/E_Way_Bill/view/EwayBillView.jsx")
);
const EwayBillWithIrnView = lazy(() =>
  import("../pages/E_Way_Bill/external/view/EwayBillWithIrnView.jsx")
);
const EwayBillWithOutIrnView = lazy(() =>
  import("../pages/E_Way_Bill/external/view/EwayBillWithOutIrnView.jsx")
);
const EwayBillCreateFormWithTaxInvoice = lazy(() =>
  import("../components/invoice/CreateInvoiceForm.jsx")
);

const EwayBillTaxInvoiceView = lazy(() =>
  import("../pages/E_Way_Bill/internal/view/EwayBillWithTaxInvoiceView.jsx")
);
const EwayBillEInvoiceView = lazy(() =>
  import("../pages/E_Way_Bill/internal/view/EwayBillWithEInvoiceView.jsx")
);
const EInvoice = lazy(() => import("../pages/e-invoice/EInvoice.jsx"));
const UpdateSubscriptionPage = lazy(() =>
  import("../pages/update-subscription/UpdateSubscriptionPage.jsx")
);
const UpdateSubscriptionWithTrialPage = lazy(() =>
  import("../pages/subscription-with-trial/UpdateSubscriptionWithTrialPage.jsx")
);
const SubscriptionHistoryPage = lazy(() =>
  import("../pages/subscription-history/SubscriptionHistoryPage.jsx")
);
const Recurring_Invoice = lazy(() =>
  import("../pages/Recurring_Invoice/Recurring_Invoice_Dashboard.jsx")
);
const Recurring_Invoice_View = lazy(() =>
  import("../pages/Recurring_Invoice/Recurring_Invoice_View.jsx")
);
export const routes = {
  signup: {
    path: "/signup",
    element: Signup,
  },
  signin: {
    path: "/signin",
    element: Signin,
  },

  user: {
    path: "/user",
    element: PrivateRoute,
  },
  dashboard: {
    path: "dashboard",
    element: Dashboard,
  },
  Recurring_Invoice: {
    path: "Recurring_Invoice",
    element: Recurring_Invoice,
  },
  Recurring_Invoice_View: {
    path: "Recurring_Invoice/Recurring_Invoice_View/:id",
    element: Recurring_Invoice_View,
  },
  invoice: {
    path: "invoice/tax-invoice",
    element: Invoice,
  },
  create_tax_invoice: {
    path: "invoice/tax-invoice/new",
    element: CreateTaxInvoice,
  },
  AllSelfInvoice: {
    path: "invoice/self-invoice",
    element: SelfInvoice,
  },
  create_self_invoice: {
    path: "invoice/self-invoice/new",
    element: CreateSelfInvoice,
  },
  create_credit_note_from_taxInvoice: {
    path: "invoice/tax-invoice/credit-note/new/:invoiceId",
    element: CreateCreditNote,
  },
  create_credit_note_from_eInvoice: {
    path: "invoice/e-invoice/credit-note/new/:einvoiceId",
    element: CreateCreditNote,
  },
  create_debit_note_from_taxInvoice: {
    path: "invoice/tax-invoice/debit-note/new/:invoiceId",
    element: CreateDebitNote,
  },
  create_debit_note_from_eInvoice: {
    path: "invoice/e-invoice/debit-note/new/:einvoiceId",
    element: CreateDebitNote,
  },
  eInvoice: {
    path: "invoice/e-invoice",
    element: EInvoice,
  },
  create_e_invoice: {
    path: "invoice/e-invoice/new",
    element: CreateEInvoice,
  },
  tax_invoice_edit: {
    path: "tax-invoice/edit/:invoiceId",
    element: EditInvoicePage,
  },
  invoiceConverter: {
    //for estimate to invoice
    path: "invoice/invoiceConverter/:estimateId",
    element: invoiceConverter,
  },
  invoiceToEwayBillConverter: {
    // for invoice to e-waybill
    path: "invoice/invoiceToEwayBillConverter/:invoiceId",
    element: TaxInvoiceToEwaybill,
  },
  envoiceToEwayBillConverter: {
    // for e-invoice to e-waybill
    path: "envoice/e-invoiceToEwayBillConverter/:einvoiceId",
    element: EinvoiceToEwayBill,
  },
  einvoiceConverter: {
    // for estimate to  einvoice
    path: "invoice/einvoiceConverter/:estimateId",
    element: einvoiceConverter,
  },
  profileSetUp: {
    path: "profileSetUp",
    element: profileSetUp,
  },
  Products: {
    path: "items",
    element: Products,
  },
  ProductView: {
    path: "ProductView/:id",
    element: ProductView,
  },
  customers: {
    path: "customers",
    element: Customer,
  },
  EditCustomer: {
    path: "EditCustomer/:customer_id",
    element: EditCustomer,
  },
  EditCustomerInvoiceView: {
    path: "EditCustomerInvoiceView/:customer_id",
    element: EditCustomer,
  },

  estimate: {
    path: "estimate_management/allEstimates",
    element: Estimate,
  },
  convertedEstimate: {
    path: "estimate_management/convertedEstimate",
    element: Estimate,
  },
  estimateCreate: {
    path: "estimate_management/create",
    element: EditEstimate,
  },
  estimateEdit: {
    path: "estimate_management/edit/:estimate_id",
    element: EditEstimate,
  },
  estimateView: {
    path: "estimate_management/view/:estimate_id",
    element: EstimateView,
  },
  self_invoice_view: {
    path: "invoice/self-invoice/view/:selfInvoice_id",
    element: SelfInvoice_View,
  },
  EWayBillWithIrnCreateForm: {
    path: "e_way_bill/external/EWayBillWithIrnCreateForm",
    element: EWayBillWithIrnCreateForm,
  },
  EWayBillWithOutIrnCreateForm: {
    path: "e_way_bill/external/EWayBillWithOutIrnCreateForm",
    element: EWayBillWithOutIrnCreateForm,
  },
  EWayBillExternal: {
    path: "e_way_bill/external",
    element: EWayBillExternal,
  },
  EWayBillInternal: {
    path: "e_way_bill/internal",
    element: EWayBillInternal,
  },
  EwayBillView: {
    path: "e_way_bill/EwayBillView/:id",
    element: EwayBillView,
  },
  EwayBillWithIrnView: {
    path: "e_way_bill/external/EwayBillWithIrnView/:id",
    element: EwayBillWithIrnView,
  },
  EwayBillWithOutIrnView: {
    path: "e_way_bill/external/EwayBillWithOutIrnView/:id",
    element: EwayBillWithOutIrnView,
  },
  EwayBillTaxInvoiceView: {
    path: "e_way_bill/internal/EwayBillTaxInvoiceView/:id",
    element: EwayBillTaxInvoiceView,
  },
  EwayBillEInvoiceView: {
    path: "e_way_bill/internal/EwayBillEInvoiceView/:id",
    element: EwayBillEInvoiceView,
  },
  EwayBillCreateFormWithTaxInvoice: {
    path: "e_way_bill/EwayBillCreateFormWithTaxInvoice",
    element: EwayBillCreateFormWithTaxInvoice,
  },

  viewSingleTaxInvoice: {
    path: "tax-invoice/view/:invoiceId/:ewayBillId?",
    element: InvoiceView,
  },
  viewSingleTaxInvoiceForCustomer: {
    path: "tax-invoice/viewSingleTaxInvoiceForCustomer/:invoiceId",
    element: InvoiceView,
  },
  viewSingleEInvoice: {
    path: "e-invoice/view/:invoiceId/:ewayBillId?",
    element: EInvoiceView,
  },
  viewSingleEInvoiceForCustomer: {
    path: "tax-invoice/viewSingleEInvoiceForCustomer/:invoiceId",
    element: EInvoiceView,
  },
  // downloadViewSingleTaxInvoice: {
  //   path: "tax-invoice/download-view/:invoiceId",
  //   element: InvoiceDownloadView,
  // },
  // downloadViewSingleEWayBill: {
  //   path: "E-wayBill/download-view/:invoiceId",
  //   element: InvoiceDownloadView,
  // },
  userProfile: {
    path: "userProfile",
    element: UserProfile,
  },
  subscriptionWithTrial: {
    path: "userProfile/subscription-with-trial",
    element: UpdateSubscriptionWithTrialPage,
  },
  subscriptionUpdate: {
    path: "userProfile/update-subscription",
    element: UpdateSubscriptionPage,
  },
  subscriptionHistory: {
    path: "userProfile/subscription-history",
    element: SubscriptionHistoryPage,
  },
  invalid: {
    path: "/*",
    element: ErrorComp,
  },
};
