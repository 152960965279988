// TokenExpirePopUp.jsx
const TokenExpirePopUp = ({ message, onClose }) => {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-8 relative max-w-lg w-full shadow-lg border border-gray-300">
          <button
            className="absolute top-3 right-3 text-2xl text-gray-600 hover:text-red-600 transition-colors duration-200"
            onClick={onClose}
          >
            &times;
          </button>
          <h2 className="text-xl font-semibold text-center mb-4">Session Expired</h2>
          <p className="text-center text-gray-700">{message}</p>
          <div className="mt-6 flex justify-center">
            <button
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200"
              onClick={onClose}
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  export default TokenExpirePopUp;
  