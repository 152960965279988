import React, { useContext, useState } from 'react';
import { baseUrl, endpoints } from '../../utils/axios';
import common_service from '../../api/common_service';
import { AuthContext } from '../../context/AuthContext';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';


const UserMessage = ({ message }) => {
    return <>
        <div className="flex gap-3 my-4 text-gray-600 text-sm flex-1">
            <span className="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
                <div className="rounded-full bg-gray-100 border p-1">
                    <svg stroke="none" fill="black" strokeWidth="0" viewBox="0 0 16 16" height="20" width="20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z">
                        </path>
                    </svg>
                </div>
            </span>
            <p className="leading-relaxed">
                <span className="block font-bold text-gray-700">You</span><span dangerouslySetInnerHTML={{ __html: message }}></span>
            </p>
        </div>
    </>
}

const AiMessage = ({ aiRes }) => {
    return <div className="flex gap-3 my-4 text-gray-600 text-sm flex-1">
        <span className="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
            <div className="rounded-full bg-gray-100 border p-1">
                <svg stroke="none" fill="black" strokeWidth="1.5" viewBox="0 0 24 24" aria-hidden="true"
                    height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round"
                        d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z">
                    </path>
                </svg>
            </div>
        </span>
        <p className="leading-relaxed">
            <span className="block font-bold text-gray-700">Ravi</span> <span dangerouslySetInnerHTML={{ __html: aiRes }}></span>
        </p>
    </div>
}

const ChatComponent = () => {
    const [message, setMessage] = useState('');
    const [msgArr, setMsgArr] = useState(["<p>Hi! How can i help you today?</p>"])
    const [chatVisible, setChatVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const authData = useContext(AuthContext);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)

        try {
            let url = `${baseUrl}/${endpoints.chat.generalPurposeChat}`
            console.log(url)
            let payload = {
                previousChat: msgArr,
                question: message
            }
            let token = authData?.authData?.accessToken
            let response = await common_service.POST_REQUEST(url, token, payload)
            setMsgArr(prev => [...prev, message, response.data?.answer || "Sorry, I am not able to answer at the moment. Please Contact support@nextbill.io for further help!"])
            setMessage("")
            setLoading(false)
        } catch (error) {
            console.log(error)
            setMsgArr(prev => [...prev, message, "Sorry, I am not able to answer at the moment. Please Contact support@nextbill.io for further help!"])
            setMessage("")
            setLoading(false)
        }
    }

    return (
        <div className="z-[100]">
            {/* Floating Button */}
            <button
                onClick={() => { setChatVisible(prev => !prev) }}
                className="fixed bottom-4 right-4 inline-flex items-center justify-center text-sm font-medium disabled:pointer-events-none disabled:opacity-50 border rounded-full w-16 h-16 bg-[#6326ef] hover:bg-[#6326ef90] m-0 cursor-pointer border-gray-200 bg-none p-0 normal-case leading-5 hover:text-gray-900"
                type="button" aria-haspopup="dialog" aria-expanded="false" data-state="closed">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="40" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                    className="text-white block border-gray-200 align-middle">
                    <path d="m3 21 1.9-5.7a8.5 8.5 0 1 1 3.8 3.8z" className="border-gray-200"></path>
                </svg>
            </button>

            {/* Chatbox */}
            {chatVisible &&
                <div style={{ boxShadow: '0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 0.05)' }}
                    className="fixed bottom-[calc(4rem)] right-[4rem] mr-4 bg-white pt-6 pr-6 pl-6 rounded-lg border border-[#e5e7eb] w-[440px] h-[600px] overflow-y-scroll">

                    {/* Heading */}
                    <div className="flex flex-col space-y-1.5 pb-6">
                        <h2 className="font-semibold text-lg tracking-tight">ChatBot</h2>
                        <p className="text-sm text-[#6b7280] leading-3">Powered By Nextbill</p>
                    </div>

                    {/* Chat Container */}
                    <div className="min-h-[425px]" style={{ minWidth: '100%', display: 'table' }}>
                        {/* Chat Message AI */}
                        {msgArr.map((msg, i) => {
                            if ((i + 1) % 2 === 0) {
                                return <UserMessage message={msg} />
                            }
                            else {
                                return <AiMessage aiRes={msg} />
                            }
                        })}
                        {loading && <div className="text-center">Thinking....</div>}
                    </div>

                    {/* Input Container */}
                    <div className='bottom-0 bg-white sticky h-[3rem] '>
                        <form className={`mt-4 flex gap-1  ${loading && "pointer-events-none opacity-50"}`} onSubmit={handleSubmit}>
                            <input
                                className={`flex h-9 w-full text-sm border-gray-300 bg-gray-100 text-gray-900 rounded-full py-2 px-3`}
                                type="text"
                                placeholder="Type your message here"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                            <button
                                className={`h-9 w-[40px] text-sm bg-[#6326ef] text-white rounded-full hover:bg-[#6326ef90] transition-all ease-in-out duration-150`}>
                                <ArrowUpwardIcon />
                            </button>
                        </form>
                    </div>
                </div>
            }
        </div>
    );
};

export default ChatComponent;
