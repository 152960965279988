import { routes } from "../routes/routes";
import dashboard_icon from "../assets/images/dashboard.png";
import invoice_management_icon from "../assets/images/Invoice.png";
import ewaybill_icon from "../assets/images/Ewaybill.png";
import customer from "../assets/images/customers.png";
import Recurring_Invoice from "../assets/images/Recurring_Invoice.png";
import Items from "../assets/images/sidebarnew/Item.png";
import Estimate from "../assets/images/sidebarnew/Estimate.png";

export const SIDEBAR_DATA = [
  {
    name: "dashboard",
    title: "Dashboard",
    route: routes.dashboard.path,
    icon: dashboard_icon,
    subcategories: [],
  },
  {
    name: "invoice",
    title: "Invoice Management",
    route: routes.invoice.path,
    icon: invoice_management_icon,
    subcategories: [
      {
        name: "tax-invoice",
        route: routes.invoice.path,
        title: "Tax Invoice",
        // icon: DescriptionOutlinedIcon,
      },
      {
        name: "e-invoice",
        route: routes.eInvoice.path,
        title: "E Invoice",
        // icon: DescriptionOutlinedIcon,
      },
      {
        name: "self-invoice",
        route: routes.create_self_invoice.path,
        title: "Self Invoice",
        // icon: DescriptionOutlinedIcon,
      },
    ],
  },
  {
    name: "Recurring_Invoice",
    title: "Recurring Invoice",
    route: routes.Recurring_Invoice.path,
    icon: Recurring_Invoice,
    subcategories: [],
  },
  {
    name: "estimate_management/allEstimates",
    title: "Estimate Management",
    route: routes.estimate.path,
    icon: Estimate,
    subcategories: [],
  },
  {
    name: "e_way_bill",
    title: "E-way Bill",
    route: routes.EWayBillInternal.path,
    icon: ewaybill_icon,
    subcategories: [
      {
        name: "internal",
        title: "E-Way Bill with invoice",
        route: routes.EWayBillInternal.path,
        // icon: ewaybill_icon,
      },
      {
        name: "external",
        title: "E-Way Bill without invoice",
        route: routes.EWayBillExternal.path,
        // icon: ewaybill_icon,
      },
    ],
  },
  {
    name: "items",
    title: "Items",
    route: routes.Products.path,
    icon: Items,
    subcategories: [],
  },
  {
    name: "customers",
    title: "Customers",
    route: routes.customers.path,
    icon: customer,
    subcategories: [],
  },
];
