import React, { createContext } from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import { AuthProvider } from "./context/AuthContext.jsx";
import "react-toastify/dist/ReactToastify.css";
import { store } from "./redux/store.jsx";
import { Provider } from "react-redux";
import { ModalProvider } from "./context/ModalProvider.jsx";
import { FunctionProvider } from "./context/FunctionContext.jsx";

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <AuthProvider>
      <ModalProvider>
        <FunctionProvider>
          <App />
        </FunctionProvider>
      </ModalProvider>
    </AuthProvider>
  </Provider>
);
