import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

const globalSlice = createSlice({
	name: "global",
	initialState: {
		popUp:{
			type:null,
		    index:null
		},
		subNavigationIndex:0
	},
	reducers: {
		addGlobalAnySliceData: function (state, action) {
			return action.payload
		}
	},
})
export const {
	addGlobalAnySliceData
} = globalSlice.actions
export default globalSlice.reducer