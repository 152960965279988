import { routes } from "../routes/routes";

let PermissionArray = [
  {
    page: routes.eInvoice.path,
    Einvoice_applicable: [true],
  },
  {
    page: routes.EwayBillCreateFormWithTaxInvoice.path,
    Company_type: ["Product", "Both"],
  },
  {
    page: routes.EWayBillWithIrnCreateForm.path,
    Company_type: ["Product", "Both"],
  },
  {
    page: routes.EWayBillWithIrnCreateForm.path,
    Company_type: ["Product", "Both"],
  },
  {
    page: routes.Recurring_Invoice.path,
    Company_type: ["Service", "Both"],
  },
  {
    page: routes.EWayBillExternal.path,
    Company_type: ["Product", "Both"],
  },
  {
    page: routes.EWayBillInternal.path,
    Company_type: ["Product", "Both"],
  },
  {
    page: routes.EwayBillEInvoiceView.path,
    Company_type: ["Product", "Both"],
  },
  {
    page: routes.EwayBillEInvoiceView.path,
    Company_type: ["Product", "Both"],
  },
  {
    page: routes.EwayBillTaxInvoiceView.path,
    Company_type: ["Product", "Both"],
  },
];

export const checkPermission = (companyData, route) => {
  let isPermitted = false,
    isIncludedInArray = false;
  PermissionArray.forEach((el) => {
    let objArr = Object.keys(el);
    if (route.includes(el.page)) {
      isIncludedInArray = true;
      isPermitted = el[objArr[1]].includes(companyData[objArr[1]]);
    }
  });
  return isIncludedInArray ? isPermitted : true;
};
