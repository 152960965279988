import React from "react";

function ErrorsComponent() {
  return (
    <div className="mt-10 ml-20 w-[100vw]">
      <section className="page_404">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="col-sm-10 col-sm-offset-1 text-center">
                <div className="four_zero_four_bg bg-gray-800 text-white py-16">
                  <p className="text-center text-3xl font-bold"></p>
                </div>

                <div className="contant_box_404 mt-10">
                  <h3 className="h2 text-2xl font-semibold">Server Down</h3>

                  <p className="mt-4 text-gray-600">
                    This page is currently not available. Please try again
                    later!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ErrorsComponent;
