import { createSlice } from "@reduxjs/toolkit";

const selfInvoiceSlice = createSlice({
  name: "selfinvoice",
  initialState: {
    totalCount: 0,
    SelfInvoicePaginationData: {
      currentPage: 1,
      searchText: "",
      isLoading: true,
    },
    allSelfInvoiceData: [],
  },
  reducers: {
    addSelfInvoiceSliceeAnyData: function (state, action) {
      return action.payload;
    },
    addSelfInvoicePaginationData: function (state, action) {
      state.SelfInvoicePaginationData = action.payload;
    },
    addAllSelfInvoiceData: function (state, action) {
      state.allSelfInvoiceData = action.payload;
    },
  },
});
export const {
  addSelfInvoicePaginationData,
  addAllSelfInvoiceData,
  addSelfInvoiceSliceeAnyData,
} = selfInvoiceSlice.actions;
export default selfInvoiceSlice.reducer;
