import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const ewaybillSlice = createSlice({
  name: "ewaybill",
  initialState: {
    ewayBillExternalData: null,
    ewayBillInternalData: null,
    ewayBillExternalPaginationData: {
      search: "",
      currentPage: 1,
      isLoading: true,
    },
    externalTotalPageCount: 1,
    internalPaginationData: {
      search: "",
      currentPage: 1,
      isLoading: true,
    },
    internalTotalPageCount: 1,
    Filter: {
      EwayBillWithOutInvoice: null,
      EwayBillWithInvoice: "all",
    },
    convertoerPopUps: null,
  },
  reducers: {
    addEwayBillExternalData: function (state, action) {
      state.ewayBillExternalData = action.payload;
    },
    addEwayBillInternalData: function (state, action) {
      state.ewayBillInternalData = action.payload;
    },
    addEwayBillExternalPaginationData: function (state, action) {
      state.ewayBillExternalPaginationData = action.payload;
    },
    addEwayBillInternalPaginationData: function (state, action) {
      state.internalPaginationData = action.payload;
    },
    addAntData: function (state, action) {
      return action.payload;
    },
  },
});
export const {
  addEwayBillExternalData,
  addEwayBillInternalPaginationData,
  addEwayBillInternalData,
  addEwayBillExternalPaginationData,
  addAntData,
} = ewaybillSlice.actions;
export default ewaybillSlice.reducer;
