import { createAsyncThunk, createSlice } from '@reduxjs/toolkit' 


const recurring_InvoiceSlice=createSlice({
    name:"item",
    initialState:{
        Recurring_InvoiceSliceData:null,
        singleItem:null,
        paginationData:{
          currentPage: 1,
          searchText: "",
          isLoading:true,
          totalCount:0
        },
    },
    reducers:{
        addAnyRecurring_InvoiceSliceData: function(state,action){
        return action.payload;
     },
     addRecurringPaginationData:function(state,action){
        state.paginationData=action.payload
     }
    }
})
export const {addAnyRecurring_InvoiceSliceData,addRecurringPaginationData}=recurring_InvoiceSlice.actions;
export default recurring_InvoiceSlice.reducer

