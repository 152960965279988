import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

const companySlice = createSlice({
	name: "company",
	initialState: {
		companyData: null,
		companyGSTList: null,
		companyBankData: null,
		companyUpiData: null,
		selectedBankAccount: null,
		selectedBankUPI: null,
		loading: null,
	},
	reducers: {
		addCompanyData: function (state, action) {
			state.companyData = action.payload
		},
		addCompanyGstList: function (state, action) {
			state.companyGSTList = action.payload
		},
		addCompanyBankData: function (state, action) {
			state.companyBankData = action.payload
		},
		addCompanyUpiData: function (state, action) {
			state.companyUpiData = action.payload
		},
		addSelectedBankAcconut: function (state, action) {
			state.selectedBankAccount = action.payload
		},
		addSelectedBankSlice: function (state, action) {
			state.selectedBankUPI = action.payload
		},
		addLoding: function (state, action) {
			state.loading = action.payload
		},
	},
})
export const {
	addCompanyData,
	addCompanyGstList,
	addCompanyBankData,
	addCompanyUpiData,
	addSelectedBankAcconut,
	addSelectedBankSlice,
	addLoding,
} = companySlice.actions
export default companySlice.reducer
